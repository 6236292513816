@charset "UTF-8";
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  min-height: 100vh;
  max-height: 100%; }

img {
  max-width: 100%; }

a {
  text-decoration: none; }

ul {
  list-style: none;
  padding: 0; }

::selection {
  background: #93BB2C;
  color: #fff; }

::-moz-selection {
  background: #93BB2C;
  color: #fff; }

::-webkit-selection {
  background: #93BB2C;
  color: #fff; }

.nb-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  margin: 0;
  background: transparent;
  border-top: 4px solid #17BED2;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite; }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/*--------------------------------------------------------------
# Preloader
// --------------------------------------------------------------*/
#preloader {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999; }

.main {
  margin-top: 62px;
  height: 100%; }

.fancybox__container {
  z-index: 1060; }

.spinner-5 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #034981;
  -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 70deg, #0000 71deg 90deg), radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 calc(100% - 8px));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: s5 1s infinite; }

@keyframes s5 {
  to {
    transform: rotate(0.5turn); } }

.header {
  background-color: #93BB2C;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08); }
  .header__menu {
    margin: 0 auto; }
  .header__сallback a {
    background-color: #034981;
    padding: 0.5rem 1rem;
    font-weight: 500;
    border: 2px solid #034981;
    transition: 0.15s;
    text-align: center;
    color: #fff; }
    .header__сallback a:hover {
      background-color: #fff;
      color: #034981; }
  .header__contacts-wrap {
    margin-right: 3rem; }
  .header__contacts {
    display: flex;
    align-items: center; }
  .header .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #fff; }
  .header__phone {
    font-weight: 500;
    font-size: 16px; }
    .header__phone a {
      color: #034981;
      transition: all 0.15s ease-in-out 0s; }
      .header__phone a:hover {
        color: #333333; }
  .header__lk {
    font-size: 14px; }
    .header__lk a {
      color: #fff; }
  .header__menu .nav-item {
    margin-right: 1rem; }
    .header__menu .nav-item:last-child {
      margin-right: 0; }
    .header__menu .nav-item .nav-link {
      position: relative; }
      .header__menu .nav-item .nav-link:hover {
        color: #034981; }
  .header__navbar-brand-wrap {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.8rem; }

.headroom {
  will-change: transform;
  transition: transform 200ms linear; }

.headroom--pinned {
  transform: translateY(0); }

.headroom--unpinned {
  transform: translateY(-100%); }

.navbar-toggler {
  height: 45px;
  width: 45px;
  padding: 0.6rem;
  border: 2px solid #034981;
  border-radius: 0.2rem;
  transition: all ease 0.15s; }
  .navbar-toggler svg {
    color: #034981; }
  .navbar-toggler:focus {
    box-shadow: none;
    background-color: #034981; }
  .navbar-toggler:focus svg {
    color: #fff; }
  .navbar-toggler:hover {
    background-color: #034981; }
  .navbar-toggler:hover svg {
    color: #fff; }

.navbar-brand {
  position: relative;
  display: flex;
  font-weight: 500;
  color: #333333;
  font-size: 16px;
  background-color: #fff;
  margin: 0;
  padding-right: 3rem; }
  .navbar-brand::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    width: 100vw;
    z-index: -1;
    height: 100%; }
  .navbar-brand::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom: 62px solid #93BB2C;
    border-left: 45px solid transparent; }
  .navbar-brand span {
    font-size: 13px;
    font-weight: 600; }
  .navbar-brand:hover {
    color: #333333; }

.navbar {
  padding: 0; }

.footer {
  background-color: #fff; }
  .footer__bottom {
    padding: 0.6rem 0;
    border-top: 1px solid #dcdcdc;
    display: flex;
    justify-content: space-between; }
  .footer__wrap {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0; }
  .footer__copyright-text {
    margin: 0;
    font-size: 12px; }
    .footer__copyright-text p {
      margin: 0; }
  .footer__contacts {
    display: flex;
    justify-content: flex-end; }
    .footer__contacts a {
      display: flex;
      align-items: center;
      color: #333333;
      transition: color 0.2s; }
      .footer__contacts a:hover {
        color: #93BB2C; }
    .footer__contacts i {
      font-size: 20px;
      margin-right: 0.5rem; }
  .footer__links ul {
    display: flex;
    margin: 0; }
  .footer__links li {
    margin-right: 2rem; }
    .footer__links li:last-child {
      margin: 0; }
  .footer__links a {
    color: #333333;
    font-size: 12px;
    transition: color 0.2s; }
    .footer__links a:hover {
      color: #93BB2C; }

.company__images {
  height: 100%; }
  .company__images img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.company__text {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 3.125rem;
  background-color: #93BB2C;
  color: #fff;
  font-size: 18px; }

.company-tasks {
  padding: 3rem 0; }
  .company-tasks .title {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 30px; }

.home__item {
  margin-bottom: 2rem; }
  .home__item:last-child {
    margin-bottom: 0; }

.home__item-name {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 1.5rem; }

.home__item-wrap {
  display: flex; }

.home__item-image {
  flex: 0 0 40%; }
  .home__item-image img {
    width: 100%;
    height: 100%; }

.home__item-info {
  flex: 0 0 15%;
  background-color: #93BB2C; }

.home__item-text {
  background-color: #034981;
  color: #fff;
  padding: 2rem; }
  .home__item-text .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 1.525rem; }
  .home__item-text p {
    margin: 0; }

.home__item-info {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly; }

.home__item-info-value {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 500;
  color: #fff;
  font-size: 30px; }
  .home__item-info-value span {
    font-size: 16px;
    color: #034981; }

.documents {
  padding: 3rem 0;
  background-color: #93BB2C; }
  .documents .title {
    color: #fff; }
  .documents__item {
    display: flex;
    align-items: center;
    margin-top: 2rem; }
    .documents__item i {
      margin-right: 1rem; }
    .documents__item a {
      font-size: 17px;
      font-weight: 400;
      color: #fff;
      transition: color 0.3s; }
      .documents__item a:hover {
        color: #333333; }
  .documents__icons {
    background: url("../img/icons/document.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px; }

#map {
  height: 400px; }

.btn-details {
  display: inline-block;
  padding: 0.65rem 1.25rem;
  color: #fff;
  background: #034981;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
  border: 2px solid #034981; }
  .btn-details:hover {
    background: #fff;
    color: #034981; }

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 30px;
  bottom: 30px;
  z-index: 996;
  background: #034981;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 0.2rem;
  transition: background-color .15s;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); }
  .back-to-top i {
    font-size: 22px;
    color: #fff; }
  .back-to-top:hover {
    background-color: #fff; }
    .back-to-top:hover i {
      color: #034981; }

.back-to-top.active {
  visibility: visible;
  opacity: 1; }

.slide-main {
  position: relative;
  overflow: hidden; }
  .slide-main .swiper-slide {
    height: 600px;
    background-size: cover;
    background-position: center bottom; }
  .slide-main .slide-next,
  .slide-main .slide-prev {
    position: absolute;
    z-index: 1;
    background-color: #034981;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .slide-main .slide-next i,
    .slide-main .slide-prev i {
      font-size: 22px;
      color: #fafafa; }
  .slide-main .slide-next {
    right: 0;
    top: 40%; }
  .slide-main .slide-prev {
    left: 0;
    top: 40%; }

.slide-home {
  height: 100%; }

.swiper-button-disabled {
  opacity: 0.8; }

.features {
  background: #E5E5E5;
  padding: 3rem 0; }
  .features .title {
    padding: 0;
    margin: 0 0 2rem;
    font-size: 30px; }
  .features p {
    margin-bottom: 2rem; }
  .features__item-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  .features__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    width: 100%;
    height: 200px;
    background-color: #034981;
    padding: 1rem;
    text-align: center;
    clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%); }
  .features__item-title {
    font-size: 40px;
    font-weight: 500;
    color: #93BB2C; }
  .features__item-text {
    color: #fff; }

.offcanvas__menu li {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 1rem;
  text-transform: uppercase; }

.offcanvas__menu a {
  z-index: 2;
  flex: 1;
  display: flex;
  transition: all 0.3s ease-in-out 0s;
  width: 100%;
  color: #333333; }
  .offcanvas__menu a:hover {
    color: #034981; }

.offcanvas__contacts {
  padding-top: 1rem;
  margin-bottom: 1rem;
  border-top: 1px solid #dcdcdc; }
  .offcanvas__contacts .phone {
    margin-bottom: 0.5rem; }
  .offcanvas__contacts a {
    flex: 1;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    color: #333333; }

.offcanvas__button {
  top: 10px;
  border: 0;
  height: 40;
  width: 40px;
  position: absolute;
  z-index: 1;
  right: 0;
  background-color: transparent;
  transition: all 0.3s ease-in-out 0s; }
  .offcanvas__button i {
    font-size: 30px; }

.offcanvas {
  background-color: #f5f8fb; }

.offcanvas-top {
  height: 300px; }

.btn-close::before {
  content: '';
  background-color: #333333; }

.modal__callBack .modal-content {
  border-radius: 0;
  border: 0;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; }

.modal__callBack .form-control {
  padding: 0.725rem 0.75rem;
  border-radius: 0; }

.modal__callBack .modal-body {
  padding: 1rem 3rem 2rem; }

.modal__callBack .modal-header {
  border-radius: 0; }

.modal__callBack .col-form-label {
  font-size: 14px;
  font-weight: 500; }

.modal__callBack .modal-title {
  font-size: 16px; }

.modal__callBack .modal-header {
  background: #93BB2C;
  color: #fff; }

.modal__callBack .form-control:focus {
  border-color: #034981;
  border-width: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 230, 0, 0.6);
  box-shadow: inset 0 0 0 1px #034981;
  outline: 0; }

.modal__callBack .btn-close:focus {
  box-shadow: none; }

input::placeholder,
textarea::placeholder {
  font-size: 14px; }

.modal-open .main {
  filter: blur(5px); }

.modal-open .header {
  filter: blur(5px); }

.news {
  padding: 3rem 0; }
  .news .title {
    margin-bottom: 2rem; }
  .news .meta-details {
    margin-bottom: 0.5rem; }
    .news .meta-details a {
      font-size: 14px;
      font-weight: 500;
      color: #93BB2C; }
  .news__item {
    border: 1px solid #eee;
    background: #fff;
    border-radius: 0.3rem;
    margin-bottom: 25px; }
    .news__item:hover img {
      transform: scale(1.1); }
  .news__content-body {
    padding: 1rem 2rem; }
  .news__item-title {
    font-size: 18px;
    font-weight: 600; }
    .news__item-title a {
      transition: all 0.4s ease;
      color: #333333; }
      .news__item-title a:hover {
        color: #034981; }
  .news__item-image {
    overflow: hidden;
    height: 250px; }
    .news__item-image img {
      width: 100%;
      height: 100%;
      transition: all 0.4s ease; }

@media (max-width: 320px) {
  .offcanvas__menu li {
    margin-bottom: 0.5rem; }
  .offcanvas-body .offcanvas__menu li {
    font-size: 14px; }
  .offcanvas-top {
    height: 250px; } }

@media (max-width: 400px) {
  .navbar-brand {
    padding-right: 1.6rem; }
  .header__navbar-brand-wrap {
    margin-left: 0.5rem;
    font-size: 12px; }
  .navbar-brand span {
    font-size: 12px; }
  .navbar-toggler {
    height: 35px;
    width: 35px;
    padding: 0.3rem; }
  .navbar-toggler svg {
    width: 19px;
    height: 19px; }
  .offcanvas__menu li {
    font-size: 16px; }
  .offcanvas__contacts a {
    font-size: 18px; } }

@media (max-width: 460px) {
  .footer__links ul {
    flex-direction: column; }
  .footer__bottom .footer__links li {
    margin-right: 0; } }

@media (max-width: 575px) {
  .features .features__item-wrap {
    padding-top: 2rem; }
  .features__item {
    height: 150px; }
  .features__item-title {
    font-size: 30px; }
  .slide-main .slide-next, .slide-main .slide-prev {
    display: none; }
  .footer__copyright-text {
    order: 2; }
  .footer__links {
    order: 1; }
  #map {
    height: 300px; }
  .home__item-title {
    font-size: 20px; }
  .home__item-value {
    font-size: 25px; }
  .home__item-value span {
    font-size: 16px; }
  .footer__contacts i {
    font-size: 18px; }
  .slide .slide-main .swiper-slide {
    height: 250px; }
  .footer__contacts a {
    font-size: 14px; }
  .documents {
    padding: 2rem 0; }
  .documents__item {
    margin-top: 1rem; }
  .documents__item a {
    font-size: 14px; }
  .documents__icons {
    width: 30px;
    height: 30px; }
  .documents__item i {
    margin-right: 0.5rem; }
  .features .title {
    font-size: 20px;
    margin-bottom: 1rem; }
  .features p {
    font-size: 14px; }
  .company__text {
    padding: 1rem;
    font-size: 16px; }
  .company-tasks .title {
    font-size: 20px;
    margin-bottom: 1rem; }
  .company-tasks__text p {
    font-size: 14px; }
  .company-tasks {
    padding: 2rem 0 1rem; }
  .home__item-name {
    font-size: 20px;
    margin-bottom: 1rem; }
  .home__item-info {
    padding: 1rem; }
  .home__item-info-value {
    font-size: 25px; }
  .home__item-text .title {
    font-size: 18px;
    margin-bottom: 0.5rem; }
  .home__item-text p {
    font-size: 14px; }
  .home__item-info-value span {
    font-size: 14px; }
  .news {
    padding: 2rem 0; }
  .documents .title {
    font-size: 18px; }
  .features__item-text {
    font-size: 14px; }
  .features {
    padding: 2rem 0; } }

@media (max-width: 767px) {
  .slide-main .swiper-slide {
    height: 350px; }
  .home__item-slide .slide-home .swiper-slide {
    max-height: 350px; }
  .features__item-wrap {
    flex-wrap: wrap; }
  .features__item {
    max-width: 100%;
    clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%); } }

@media (max-width: 991px) {
  .features__item-wrap {
    padding-top: 3rem;
    flex-direction: row;
    justify-content: space-between; }
  .features__item {
    margin-right: 1rem; }
  .home__item-wrap {
    flex-direction: column; }
  .footer__wrap {
    flex-direction: column;
    align-items: center;
    padding: 1rem 0 0; }
  .footer__contacts {
    margin-bottom: 1rem; }
  .footer__logo {
    margin-bottom: 1rem; }
  .footer__bottom {
    flex-direction: column;
    align-items: center; }
  .footer__links li {
    margin-right: 1rem; } }
