.company {
    &__images {
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 3.125rem;
        background-color: $green;
        color: $white;
        font-size: 18px;
    }
}

.company-tasks {
    padding: 3rem 0;
    .title {
        margin-bottom: 3rem;
        text-align: center;
        font-size: 30px;
    }
}