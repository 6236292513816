.documents {
    padding: 3rem 0;
    background-color: $green;
    .title {
        color: $white;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-top: 2rem;


        i {
            margin-right: 1rem;
        }

   

        a {
            font-size: 17px;
            font-weight: 400;
            color: $white;
            transition: color 0.3s;

            &:hover {
                color: $dark-gray;
            }
        }


    }

    &__icons {
        background: url('../img/icons/document.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 40px;
        height: 40px;

    }
}

#map {
    height: 400px;
}