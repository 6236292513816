

@media (max-width: 320px) {
  .offcanvas__menu li {
    margin-bottom: 0.5rem;
  }

  .offcanvas-body .offcanvas__menu li {
    font-size: 14px;
  }

  .offcanvas-top {
    height: 250px;
  }

}






@media (max-width: 400px) {
  .navbar-brand {
    padding-right: 1.6rem;
  }

  .header__navbar-brand-wrap {
    margin-left: 0.5rem;
    font-size: 12px;
  }

  .navbar-brand span {
    font-size: 12px;
  }
.navbar-toggler {
  height: 35px;
  width: 35px;
  padding: 0.3rem;
}

.navbar-toggler svg {
  width: 19px;
  height: 19px;
}

.offcanvas__menu li {
  font-size: 16px;
}

.offcanvas__contacts a {
  font-size: 18px;
}
}


@media (max-width: 460px) {
  .footer__links ul {
    flex-direction: column;
  }

  .footer__bottom .footer__links li {
    margin-right: 0;
  }
}





@media (max-width: 575px) {

  .features .features__item-wrap {
    padding-top: 2rem;
  }

  .features__item {
    height: 150px;

  }

  .features__item-title {
    font-size: 30px;
  }

  .slide-main .slide-next, .slide-main .slide-prev {
    display: none;
  }

  .footer__copyright-text {
    order: 2;
  }

  .footer__links {
    order: 1;
  }

   #map {
    height: 300px;
  }

  .home__item-title {
    font-size: 20px;
  }

  .home__item-value {
    font-size: 25px;
  }

  .home__item-value span {
    font-size: 16px;
  }

  .footer__contacts i {
    font-size: 18px;
  }

  .slide .slide-main .swiper-slide {
    height: 250px;
  }

  .footer__contacts a {
    font-size: 14px;
  }

  .documents {
    padding: 2rem 0;
  }

  .documents__item {
    margin-top: 1rem;
  }

  .documents__item a {
    font-size: 14px;
  }

  .documents__icons {
    width: 30px;
    height: 30px;
   
  }

  .documents__item i {
    margin-right: 0.5rem;
  }


  .features .title {
    font-size: 20px;
    margin-bottom: 1rem;
  }

  .features p {
    font-size: 14px;
  }

  .company__text {
    padding: 1rem;
    font-size: 16px;
  }

  .company-tasks .title {
    font-size: 20px;
    margin-bottom: 1rem;
  }

  .company-tasks__text p {
    font-size: 14px;
  } 

  .company-tasks {
    padding: 2rem 0 1rem;
  }

  .home__item-name {
    font-size: 20px;
    margin-bottom: 1rem;
  }

  .home__item-info {
    padding: 1rem;
  }

  .home__item-info-value {
    font-size: 25px;
  }

  .home__item-text .title {
    font-size: 18px;
    margin-bottom: 0.5rem;
  }

  .home__item-text p {
    font-size: 14px;
  }

  .home__item-info-value span {
    font-size: 14px;
  }

  .news {
    padding: 2rem 0;
  }

  .documents .title {
    font-size: 18px;
  }

  .features__item-text {
    font-size: 14px;
  }

  .features {
    padding: 2rem 0;
  }

  
}

@media (max-width: 767px) {
  .slide-main .swiper-slide {
    height: 350px;
  }
  .home__item-slide .slide-home .swiper-slide {
    max-height: 350px;
  }

  .features__item-wrap {
    flex-wrap: wrap;
  }

  .features__item {
    max-width: 100%;
    clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
  }


}

@media (max-width: 991px) {

  .features__item-wrap {
    padding-top: 3rem;
    flex-direction: row;
    justify-content: space-between;
  }

  .features__item {
    margin-right: 1rem;
  }

  .home__item-wrap {
    flex-direction: column;
  }

  .footer__wrap {
    flex-direction: column;
    align-items: center;
    padding: 1rem 0 0;
  }

  .footer__contacts {
    margin-bottom: 1rem;
  }

  .footer__logo {
    margin-bottom: 1rem;
  }

  .footer__bottom {
    flex-direction: column;
    align-items: center;
  }

  .footer__links li {
    margin-right: 1rem;
  
  }





}



