.modal {
    &__callBack {
        .modal-content {
            border-radius: 0;
            border: 0;
            box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        }

        .form-control {
            padding: 0.725rem 0.75rem;
            border-radius: 0;
        }

        .modal-body {
            padding: 1rem 3rem 2rem;
        }

        .modal-header {
            border-radius: 0;
        }

        .col-form-label {
            font-size: 14px;
            font-weight: 500;
        }

        .modal-title {
            font-size: 16px;
        }

        .modal-header {
            background: $green;
            color: $white;
        }

        .form-control:focus {
            border-color: $blue;
            border-width: 1px;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(255,230,0,.6);
            box-shadow: inset 0 0 0 1px $blue;
            outline: 0;
        }

        .btn-close:focus {
            box-shadow: none;
        }


    }
}

input::placeholder,
textarea::placeholder {
    font-size: 14px;
}


.modal-open .main {
    filter: blur(5px);
  }

.modal-open .header {
    filter: blur(5px);
}

