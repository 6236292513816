.features {
  background: #E5E5E5;
  padding: 3rem 0;

  .title {
    padding: 0;
    margin: 0 0 2rem;
    font-size: 30px;
  }

  p {
    margin-bottom: 2rem;
  }

  &__item-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }


  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    width: 100%;
    height: 200px;
    background-color: $blue;
    padding: 1rem;
    text-align: center;
    clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%);
  }

  &__item-title {
    font-size: 40px;
    font-weight: 500;
    color: $green;
  }

  &__item-text {
    color: $white;
  }
}
