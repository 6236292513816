.header {
  background-color: $green;
  box-shadow: 0 4px 20px rgba(0,0,0,.08);
  &__menu {
    margin: 0 auto;
  }

  &__сallback {
    a {
      background-color: $blue;
      padding: 0.5rem 1rem;
      font-weight: 500;
      border: 2px solid $blue;
      transition: 0.15s;
      text-align: center;
      color: $white;

      &:hover {
        background-color: $white;
        color: $blue;
     
        
      }
    }





  }

  &__contacts-wrap {
    margin-right: 3rem;
  }

  &__contacts {
    display: flex;
    align-items: center;

  }

  .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: $white;
  }



  &__phone {
    font-weight: 500;
    font-size: 16px;

    a {
      color: $blue;
      transition: all 0.15s ease-in-out 0s;

      &:hover {
        color: $dark-gray;
      }
    }



  }

  &__lk {
    font-size: 14px;
    a {
      color: $white;
    }
  }



  &__menu {
    .nav-item {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }


    

    .nav-link {
      position: relative;

      &:hover {
        color: $blue;
      }

 


    }

  }


}


&__navbar-brand-wrap {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.8rem;
}


}

.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}

.headroom--pinned {
  transform: translateY(0);

}

.headroom--unpinned {
  transform: translateY(-100%);
}



.navbar-toggler {
  height: 45px;
  width: 45px;
  padding: 0.6rem;
  border: 2px solid $blue;
  border-radius: 0.2rem;
  transition: all ease 0.15s;

  svg {
    color: $blue;
  }

  &:focus {
    box-shadow: none;
    background-color: $blue;
  }

  &:focus svg {
    color: $white;
  }

  &:hover {
    background-color: $blue;
  }

  &:hover svg {
    color: $white;
  }

}

.navbar-brand {
  position: relative;
  display: flex;
  font-weight: 500;
  color: $dark-gray;
  font-size: 16px;
  background-color: $white;
  margin: 0;
  padding-right: 3rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: $white;
    width: 100vw;
    z-index: -1;
    height: 100%;
  }


  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom: 62px solid  $green;
    border-left: 45px solid transparent;
  }





  span {
    font-size: 13px;
    font-weight: 600;
  }

  &:hover {
    color: $dark-gray;
  }
}

.navbar {
  padding: 0;

 

}
