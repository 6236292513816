// COLORS

$blue: #034981;
$green: #93BB2C;
$white: #fff;
$dark-gray: #333333;




$red: #DA2F4A;

$dark-blue: #0C142C;
$gray: #A8A7A7;
$light-gray: #C4C4C4;
$dark-blue-2: #687290;
$light-gray: rgb(52, 55, 69);
$light-blue: #f7faff;