.home {
    &__item {
       margin-bottom: 2rem;
       &:last-child {
           margin-bottom: 0;
       }
    }

    &__item-name {
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 1.5rem;
    }

    &__item-wrap {
        display: flex;
    }

    &__item-image {
        flex: 0 0 40%;
        img {
            width: 100%;
            height: 100%;
        }
    }

    &__item-info {
        flex: 0 0 15%;
        background-color: $green;
    }

    &__item-text {
        background-color: $blue;
        color: $white;
        padding: 2rem;

        .title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 1.525rem;
        }

        p {
            margin: 0;
        }
    }

    &__item-info {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    &__item-info-value {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-weight: 500;
        color: $white;
        font-size: 30px;
        span {
            font-size: 16px;
            color: $blue;
        }
    }
}