.footer {
    background-color: $white;

    &__bottom {
        padding: 0.6rem 0;
        border-top: 1px solid #dcdcdc;
        display: flex;
        justify-content: space-between;
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
    }

    &__copyright-text {
        margin: 0;
        font-size: 12px;

        p {
            margin: 0;
        }
    }

    &__contacts {
        display: flex;
        justify-content: flex-end;

        a {
            display: flex;
            align-items: center;
            color: $dark-gray;
            transition: color 0.2s;

            &:hover {
                color: $green;
            }
           
        }

        i {
            font-size: 20px;
            margin-right: 0.5rem;
        }
    }

    &__links {
        ul {
            display: flex;
            margin: 0;
        }
        li {
            margin-right: 2rem;
            &:last-child {
                margin: 0;
            }
  
        }

        a {
            color: $dark-gray;
            font-size: 12px;
            transition: color 0.2s;
            &:hover {
                color: $green;
            }
        }
    }


}