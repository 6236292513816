.slide-main {
  position: relative;
  overflow: hidden;
  .swiper-slide {
    height: 600px;
    background-size: cover;
    background-position: center bottom;
  }


  .slide-next,
  .slide-prev {
    position: absolute;
    z-index: 1;
    background-color: $blue;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        font-size: 22px;
        color: #fafafa;
    }
  }

  .slide-next {
      right: 0;
      top: 40%;

  }

  .slide-prev  {
      left: 0;
      top: 40%;

  }
}

.slide-home {
  height: 100%;
}

.swiper-button-disabled {
  opacity: 0.8;
}