.btn-details {
  display: inline-block;
  padding: 0.65rem 1.25rem;
  color: $white;
  background: $blue;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
  border: 2px solid $blue;

  &:hover {
    background: $white;
    color: $blue;
  }
}

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 30px;
  bottom: 30px;
  z-index: 996;
  background: $blue;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 0.2rem;
  transition: background-color .15s;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);

  i {
    font-size: 22px;
    color: $white;
  }

  &:hover {
    background-color: $white;
    i {
      color: $blue;
    }
  }
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}


