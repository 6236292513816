html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  min-height: 100vh;
  max-height: 100%;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}



::selection {
  background: #93BB2C;
  color: #fff;
}

::-moz-selection {
  background: #93BB2C;
  color: #fff;
}

::-webkit-selection {
  background: #93BB2C;
  color: #fff;
}






.nb-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  margin: 0;
  background: transparent;
  border-top: 4px solid #17BED2;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



/*--------------------------------------------------------------
# Preloader
// --------------------------------------------------------------*/

#preloader {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}


.main {
  margin-top: 62px;
  height: 100%;
  
}



.fancybox__container {
  z-index: 1060;
}






.spinner-5 {
  position: absolute;
  top: 50%;
  left: 50%;
  width:50px;
  height:50px;
  border-radius:50%;
  background:#034981;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 70deg,#0000 71deg 90deg),
    radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 calc(100% - 8px));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation:s5 1s infinite ;
}
@keyframes s5 {to{transform: rotate(.5turn)}}